<template>
  <div solution>
    <visual3
        v-if="active === 'policy'" key="policy"
        tit="<i class='kv-logo'></i>"
        txt='<p class="font40">“Solution”은 현대제철의 <b>고성능 소재</b>와<br class="mo"> <b>고도의 이용 기술</b>을 접목시킨<br> 고부가가치 건설용 제품 개발 프로젝트입니다.</p>'
        bg="solution-kv"
    />
    <visual3
        v-if="active === 'ideaApply'" key="ideaApply"
        mo="mo"
        txt='현대제철은 건설 산업 <b>고객사와의 동반성장</b> <br class="mo"> 동반성장과 미래 건설기술의 발전을 도모하고자 <p class="font50">여러분의 <b>우수한 아이디어</b>를 기다리고 있습니다.</b>'
        round="아이디어 지원하기"
        bg="solution-kv02"
    />
    <Tab :list="selectList" :active="active" @select="select"/>
    <article>
      <Paragraph class="content policy" v-if="active === 'policy'" key="policy">
        <div class="cnt-inner">
          <h3 class="para">
            소재 x 이용 기술
          </h3>
          <ul  class="cont para sol-list">
            <li  v-for="(list, idx) in contList01"
                 :key="list.idx"
                 :class="'list01-item' + (idx + 1)">
              <div class="img web">
                <img :src="'/images/pc/sol01_0' + (idx +1) + '.jpg'" alt="">
              </div>
              <div class="img mo">
                <img :src="'/images/mo/sol01_0' + (idx +1) + '.png'" alt="">
              </div>
              <p class="tit" v-html="list.tit"></p>
              <p class="txt" v-html="list.txt"></p>
            </li>
          </ul>
        </div>
        <div class="cnt-inner">
          <h3 class="para">
            기술 마케팅 지원
          </h3>
          <ul class="cont para sol-list">
            <li  v-for="(list, idx) in contList02"
                 :key="list.idx"
                 :class="'list02-item' + (idx + 1)">
              <div class="img web">
                <img :src="'/images/pc/sol02_0' + (idx +1) + '.jpg'" alt="">
              </div>
              <div class="img mo">
                <img :src="'/images/mo/sol02_0' + (idx +1) + '.png'" alt="">
              </div>
              <p class="tit" v-html="list.tit"></p>
              <p class="txt" v-html="list.txt"></p>
            </li>
          </ul>
        </div>
        <div class="cnt-inner ty2">
          <h3 class="para">
            대상 고객사
          </h3>
          <div class="para wide-img">
            <img :src="'/images/mo/sol03.png'" alt="">
          </div>
        </div>
        <div class="cnt-inner ty2">
          <h3 class="para">
            제품 개발 운영 프로세스
          </h3>
          <div class="para course-wrap">
            <ul>
              <li v-for="(cour, idx) in contList03" :key="cour.idx" :class="'cour-li' + (idx + 1)">
                <p class="tit" v-html="cour.tit"></p>
                <p class="step">
                  <span v-html="cour.num"></span>Step
                </p>
                <p class="subTit" v-html="cour.subTit01"></p>
                <p class="subTxt" v-html="cour.subTxt01"></p>
                <p class="subTit" v-html="cour.subTit02"></p>
                <p class="subTxt" v-html="cour.subTxt02"></p>
                <p class="subTit" v-html="cour.subTit03"></p>
                <p class="subTxt" v-html="cour.subTxt03"></p>
              </li>
            </ul>
          </div>
        </div>
      </Paragraph>
      <Paragraph class="content idea" v-if="active === 'ideaApply'" key="ideaApply">
        <div class="cnt-inner">
          <h3 class="para">
            과제 기본 정보
          </h3>
          <div class="contact-form03 para">
            <div class="form-list">
              <div class="list">
                <div class="tit">
                  <p>
                    연구기관
                  </p>
                </div>

                <div class="row-wrap">
                  <div class="row">
                    <p class="label">연구 기관</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.institute">
                  </div>
                  <div class="row">
                    <p class="label">연구기관 역할</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.role">
                  </div>
                  <div class="row">
                    <p class="label">회사주소</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.address">
                  </div>
                </div>
              </div>
              <div class="list">
                <div class="tit">
                  <p>
                    연구 책임자
                  </p>
                </div>

                <div class="row-wrap">
                  <div class="row">
                    <p class="label">성명 / 직위</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.name">
                  </div>
                  <div class="row">
                    <p class="label">부서</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.department">
                  </div>
                  <div class="row">
                    <p class="label">전화</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.phone">
                  </div>
                  <div class="row">
                    <p class="label">Email</p>
                    <input type="text" class="email" placeholder="입력해 주세요" v-model="param.email">
                  </div>
                </div>
              </div>
              <div class="list">
                <div class="tit">
                  <p>
                    과제 기본정보
                  </p>
                </div>
                <div class="row-wrap">
                  <div class="row">
                    <p class="label">과제명</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.taskName">
                  </div>
                  <div class="row">
                    <p class="label">연구분야</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.field">
                  </div>
                  <div class="row">
                    <p class="label">기간</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.period">
                  </div>
                  <div class="row">
                    <p class="label">연구비(원)</p>
                    <input type="text" placeholder="입력해 주세요" v-model="param.cost">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cnt-inner">
          <h3 class="para">
            과제 상세 정보
          </h3>
          <div class="contact-form02 para">
            <div class="form-list">
              <div class="list">
                <p>1. 연구배경</p>
                <textarea v-model="param.cont01" placeholder="연구배경, 형재 문제점, 필요성에 대한 내용 기술"></textarea>
              </div>
              <div class="list">
                <p>2. 연구내용 및 범위</p>
                <textarea v-model="param.cont02" placeholder="연구 아이디어, 전체 연구 내용 및 범위, 해당 제품을 통한 문제 해결 내용 기술"></textarea>
              </div>
              <div class="list">
                <p>3. 최종목표</p>
                <textarea v-model="param.cont03" placeholder="평가지표, 개발목표, 활용 계획에 대한 내용 기술"></textarea>
              </div>
              <div class="list">
                <p>4. 기대효과</p>
                <textarea v-model="param.cont04" placeholder="정량적/정성적 효과와 이에 대해한 산출 근거를 명확하게 기술"></textarea>
              </div>
              <div class="list">
                <p>5. 첨부파일 (양식 자유)</p>
                <div class="button-wrap">
                  <a class="button" @click="fileAttech">찾아보기</a>
                </div>
                <ul class="b-list">
                  <li>
                    <div class="name" id="fileNmae"></div>
                    <input style="display: none" type="file" ref="fileInput" @change="fileChange" />
                    <button type="button" @click="deleteFile"><i class="icon-x"></i></button>
                  </li>
                </ul>
              </div>
              <div class="list">
                <input id="agreeVal" ref="agreeVal" type="hidden" value="0" >
                <div class="check-box" id="noCheck" @click="AgreePolicyPopOpen"></div>
                <div class="check-box" id="yesCheck" style="display: none" @click="AgreePolicyPopOpen"><img src="/images/pc/checkbox_check.svg"></div>
                <p class="check-text" @click="AgreePolicyPopOpen">개인 정보 제공 및 활용에 동의합니다.</p>
              </div>

            </div>
            <a class="submit" @click="submit">지원서 최종 제출</a>
          </div>
        </div>
      </Paragraph>
    </article>
  </div>
</template>

<script>
import Visual3 from "@/views/components/Visual3";
import Tab from "@/views/components/Tab";
import Paragraph from "@/views/components/Paragraph";
import solution from "@/data/solution";
import ContactModal from "@/views/components/modal/ContactModal";
import axios from "axios";
import AgreePolicyPopOpen from "@/views/components/modal/AgreePivacyModal.vue";

export default {
  name: "Solution",
  components: { Visual3, Paragraph, Tab },
  data() {
    return {
      slideImgPath: "/images/pc/product-prd",
      winWidth: 0,
      param: {
        institute: '',
        role: '',
        address:'',
        name: '',
        department:'',
        phone: '',
        email: '',
        taskName:'',
        field:'',
        period:'',
        cost:'',
        cont01: '',
        cont02: '',
        cont03: '',
        cont04: '',
        site: '',
        category: '',
        company: '',
      }
    };
  },
  computed: {
    active() {
      return this.$route.params.solution
    },
    selectList() {
      return [
        {key: 'policy', label: 'policy'},
        {key: 'ideaApply', label: 'ideaApply'}
      ]
    },
    contList01(){
      return solution.contList01;
    },
    contList02(){
      return solution.contList02;
    } ,
    contList03(){
      return solution.contList03;
    }
  },
  methods: {
    contactModal(prod) {
      this.$modal(ContactModal, { category: prod.prd });
    },
    select(solution) {
      if (this.active === solution) return;
      this.$router.push({ params: { solution } })
    },

    fileAttech(){
      this.$refs.fileInput.click();
    },

    fileChange(){
      const fileData = this.$refs.fileInput.files[0];
      document.getElementById("fileNmae").innerText = fileData.name;
    },

    deleteFile(){
      this.$refs.fileInput.value = null;
      document.getElementById("fileNmae").innerText = null;
    },

    AgreePolicyPopOpen() {
      this.$modal(AgreePolicyPopOpen);
    },

    async submit() {

      if(this.$refs.agreeVal.value != 1){
        alert('개인 정보 제공 및 활용에 동의해 주십시요.');
        return;
      }

      if (!this.param.institute) {
        alert('연구기관 을(를) 입력해주세요');
        return;
      }
      if (!this.param.role) {
        alert('연구기관역할 을(를) 입력해주세요');
        return;
      }
      if (!this.param.address) {
        alert('회사주소 을(를) 입력해주세요');
        return;
      }
      if (!this.param.name) {
        alert('성명/직위 을(를) 입력해주세요');
        return;
      }
      if (!this.param.department) {
        alert('부서 을(를) 입력해주세요');
        return;
      }
      if (!this.param.phone) {
        alert('전화 을(를) 입력해주세요');
        return;
      }
      if (!this.param.email) {
        alert('이메일 을(를) 입력해주세요');
        return;
      }
      if (!this.param.taskName) {
        alert('과제명 을(를) 입력해주세요');
        return;
      }
      if (!this.param.field) {
        alert('연구분야 을(를) 입력해주세요');
        return;
      }
      if (!this.param.period) {
        alert('기간 을(를) 입력해주세요');
        return;
      }
      if (!this.param.cost) {
        alert('연구비(원) 을(를) 입력해주세요');
        return;
      }
      if (!this.param.cont01) {
        alert('1. 연구배경 을(를) 입력해주세요');
        return;
      }
      if (!this.param.cont02) {
        alert('2. 연구내용 및 범위 을(를) 입력해주세요');
        return;
      }
      if (!this.param.cont03) {
        alert('3. 최종목표 을(를) 입력해주세요');
        return;
      }
      if (!this.param.cont04) {
        alert('4. 기대효과 을(를) 입력해주세요');
        return;
      }

      const formData = new FormData();
      formData.append("institute",this.param.institute);
      formData.append("role",this.param.role);
      formData.append("address",this.param.address);
      formData.append("name",this.param.name);
      formData.append("department",this.param.department);
      formData.append("phone",this.param.phone);
      formData.append("email",this.param.email);
      formData.append("taskName",this.param.taskName);
      formData.append("field",this.param.field);
      formData.append("period",this.param.period);
      formData.append("cost",this.param.cost);
      formData.append("cont01",this.param.cont01);
      formData.append("cont02",this.param.cont02);
      formData.append("cont03",this.param.cont03);
      formData.append("cont04",this.param.cont04);
      formData.append("fileMail", this.$refs.fileInput.files[0]);

      await axios.post("/dist/solutionMail", formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          //await axios.post("/dist/sendMail", formData)
          .then((response) => {
            alert("response : " + response.data.message);
            //alert(response.message);
            //alert("메일이 성공적으로 발송되었습니다 : " + response);
            console.log("response.message : "+response.message)
          }).catch((error) => {
            alert("에러가 발생하였습니다 : " + error);
            //alert("error : " + error);
            //alert(error.message);
          });

    }

  },
};
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[solution] {
  article {
    .bgc(#fff);
    .rel;
    .crop;
    .para { transform: translateY(100px); opacity: 0; transition: transform 0.3s, opacity 0.3s; transition-timing-function: ease-out;
      &.on { transform: translateY(0); opacity: 1; }
    }

    .content{
      .p(188,50); .-box; .clear;
    }

    .cnt-inner {
      .-box;
      .rel;
      z-index: 1;
      & + .cnt-inner{
        .pt(214);
      }
    }

    .policy {
      h3 { .fs(40); .lh(40); .bold; .mb(26); .mt(-70);}
      .cnt-wrap { .p(80,0); .-box; }
      .sol-list {
        .flex; .space-between; .flex-wrap;
        .pt(0); .rel;
        gap: 32px 10px;
        li {
          .wh(calc(((100% - 10px) / 2) ),auto); .crop();
          position: relative;
          .img{
            .wh(100%, 460);
            .crop();
            img { .wf; transition: transform 0.5s; }
            &:hover img { transform: scale(1.03); }
          }
        }
      }
      .tit {
        .block();
        .mt(30);
        .fs(28);
        .lh(35.06);
        .spoqa;
        .bold;
        .c(#0075C1)
      }
      .txt {
        .block();
        .mt(30);
        .fs(20);
        .lh(30);
        .ls(-0.05em);
        .spoqa;
        .c(#333)
      }
      .wide-img{
        img{
          width:100%;
        }
      }

      .course-wrap{
        ul{
          .w(100%); .flex; .flex-wrap; .flex-column; .space-between; gap: 20px 0; .pl(50);
          background:url("/images/mo/sol04_bg02.svg") -12px 0 no-repeat;
          background-size: contain;
          .-box();
        }
        li{
          .w(100%);
          .tit{
            .mt(0);
            .pb(20);
            .pl(10);
            .rel();
            .fs(20);
            .lh(32);
            .c(#0075C1);
            &:after{
              content:'';
              .block();
              .abs();
              .b(0);
              .l(-10);
              .wh(140,10);
              background:url("/images/mo/sol04_bar.png") center center/100% no-repeat;
            }
          }
          .step{
            .mt(20);
            .pl(20);
            .fs(20);
            .lh(32);
            .c(#0075C1);
            .bold();
            span{
              .bold();
              .fs(40);
              .lh(42);
              .c(#0075C1);
              .mr(4);
            }
          }
          .subTit{
            .bold();
            .mt(20);
            .pl(20);
            .fs(18);
            .lh(24);
            .c(#333);
          }
          .subTxt{
            .keep-all;
            .mt(10);
            .pl(20);
            .fs(18);
            .lh(30);
            .c(#333);
          }
        }
      }
    }

    .idea{
      h3 { .fs(40); .lh(40); .bold; .mb(26); .mt(-63);}
      .contact-form02 {
        .w(100%);
        .-box;
        .bgc(#fff);
        .tc;
        .mh-c;
        .-b(none);
        .form-list { .mt(36);
          .list { .tl; .mb(15);
            p { .fs(18); .lh(22.54); .vam;}
            input { .fs(20); .w(80%); .spoqa; }
            span { .fs(20); .pl(20%); .-box; .mt(5); .ib; color:#0079ae; }
            textarea { .fs(14); .wh(100%,120); .mt(12); .-a(@c-black); .-box; .p(20); .spoqa; }
            textarea::placeholder { .c(#bbb);  }
            textarea + span { .pl(0); color:#ca5c5c; }
            .button-wrap{
              .mt(12);
            }
            .check-box{ .wh(27,27) !important; .ib; .-a(#d1d5db); .fl; .mr(15); .pointer;}
            .check-text{ .underline; .pointer; .fs(20); }
          }
        }
        .submit { .fs(24); .tc; .wh(250,60);  .lh(60); .bgc(#0d1112); .ib; color:#fff; .mt(40);
          &:hover { .bgc(#153347); color:#fff;}
        }
      }
      .contact-form03{
        .w(100%);
        .-box;
        .form-list{
          .list{
            .flex();
            .flex-wrap;
            .flex-column;
            height: 100%;
            //align-items: center;
            .tit{
              display: flex;
              flex: 1 31px;
              .w(100%);
              .crop();
              .mt(0);
              .-a(#137CC2);
              .-box();
              flex-direction: column;
              justify-content: center;
              .bgc(#E9EFF9);
              .-r(#137CC2);
              .tl;
              p{
                .c(#0875C1);
                .fs(24);
                .lh(24);
                .bold;
                .pl(10);
              }
            }

            .row-wrap{
              flex: 1 0 auto;
              .w(100%);
              p{
                flex: 0 97px;
                .h(60);
                .min-w(194);
                .pl(10);
                .c(#0875C1);
                .-r(#137CC2);
                .-l(#137CC2);
                .-b(#137CC2);
                .-box;
                .bgc(#E9EFF9);
                .tl;
                .fs(24);
                .lh(60);
                .vam();
                .bold;
              }
            }
            .row{
              .flex();
              flex: 1;
              .-r(#b1b1b1);
              input {
                .h(60);
                .lh(60);
                .ib;
                .vam;
                .pl(20);
                .pr(20);
                .-box;
                .-a(none);
                .fs(16);
                .spoqa;
                .-box;
                .-b(#b1b1b1);
                .c(#555555);
                flex: 1;
                &::placeholder {
                  .fs(16);
                  .c(#bbb);
                }
                &.email{
                  .c(#0875c1);
                }
              }
              &:last-of-type{
                input {
                  .-b(none);
                }
                .label{
                  .-b(none);
                }
              }
            }
            &:last-of-type{
              .row-wrap{
                .row:last-of-type{
                  p{
                    .-b(#137CC2);
                  }
                  input{
                    .-b(#B1B1B1);
                  }
                }
              }
            }
          }
        }
      }
      .button-wrap{
        .button{
          .wh(99,60); .fs(16);
          .lh(58);.tc; .spoqa; .-a(#333); .-box; .ib; transition: background-color 0.3s, transform 0.5s, opacity 0.5s; .ease-out;
          &:hover { .bgc(#153347); color:#fff;}
        }
      }
      .b-list{
        .mt(12);
        li{
          .flex;
          .space-between;
          .-box;
          &+ li{
            .mt(20);
          }
          .w(100%);
          .h(40);
          .-b();
          .-a(#333);
          .p(11,20);
          .name{
            .fs(14);
            .lh(17.53);
            .c(#000);
            .spoqa;
            &:before{
              content:'';
              display:inline-block;
              .wh(18,18);
              background:url("/images/pc/file.svg") no-repeat 0 0/100%;
              background-size:cover;
              .mr(10);
              vertical-align: middle;
              transform: translateY(-1px);
            }
          }
        }
        button{
          width: 18px;
          height: 18px;
          .wh(18,18);
          .bgc;
          .icon-x{
            display: block;
            width: 100%;
            height: 100%;
            background:url("/images/pc/x.svg") no-repeat 0 0/100%;
            background-size:cover;
          }
        }
      }
    }
  }
}
@media screen and(min-width: 1024px) {
  [solution] {
    article {
      .content {  .p(170,0,350);.max-w(1400); .mh-c;
        .cnt-inner {
          & + .cnt-inner{
            .pt(172);
          }
          & + .cnt-inner.ty2{
            .pt(212);
          }
        }
        &.policy {
          &.content{  .-box; .clear;
            h3 { .fs(40); .lh(52); .bold; .mb(40); .mt(-92);}
            .cnt-wrap { .p(80,0); .-box; }
            .sol-list {
              .flex; .space-between; .flex-wrap;
              .pt(0); .rel;
              li {
                .wh(calc(((100% - (20px * 3)) / 4) ),auto);
                .crop;

                &.list02-item4{
                  .img{
                    .-a(#D9D9D9);
                    .-box();
                  }
                }
                .img{
                  .wh(100%,auto);
                  img { .wf; transition: transform 0.5s; }
                  &:hover img { transform: scale(1.03); }
                }

                .tit {
                  .fs(28);
                  .lh(35.06);
                  .spoqa;
                  .bold;
                  .c(#0075C1)
                }
                .txt {
                  .fs(20);
                  .lh(30);
                  .ls(-0.05em);
                  .spoqa;
                  .mt(30);

                }
              }
            }

            .wide-img{
              .w(100%);
              .p(0,100);
              .-box;
              img{
                .w(100%);
              }
            }

            .course-wrap{
              ul{
                .w(calc(100% + 27px));
                .flex;
                .flex-wrap;
                .flex-row;
                .flex-ai(flex-start);
                flex-flow: wrap;
                .space-between;
                gap: 2.5vw 6.25vw;
                .pr(128px);
                .pl(70px);
                .-box();
                background: url(/images/mo/sol04_bg.svg) 0 2.5vw no-repeat;
                background-size: contain;
              }
              li{
                .w(calc((100% - 240px) / 3 ));
                .tit{
                  .mt(0);
                  .pb(20);
                  .rel();
                  .fs(20);
                  .lh(32);
                  .c(#0075C1);
                  &:after{
                    content:'';
                    .block();
                    .abs();
                    .b(0);
                    .l(0);
                    .wh(140px,10px);
                    background:url("/images/mo/sol04_bar.png") center center/100% no-repeat;
                  }
                }
                .step{
                  .mt(48);
                  .fs(20);
                  .lh(32);
                  .c(#0075C1);
                  .bold();
                  span{
                    .bold();
                    .fs(40);
                    .lh(42);
                    .c(#0075C1);
                    .mr(4);
                  }
                }
                .subTit{
                  .bold();
                  .mt(20);
                  .fs(18);
                  .lh(24);
                  .c(#333);
                }
                .subTxt{
                  .keep-all;
                  .mt(10);
                  .fs(18);
                  .lh(30);
                  .c(#333);
                }
                &:nth-child(4){
                  order: 6;
                }
                &:nth-child(5){
                  order: 5;
                }
              }
            }
          }
        }
        &.idea{
          .contact-form03{
            .w(97.2%);
            .p(0,80);
            .-box;
            .form-list{
              .-t(#137CC2);
              .-b(#137CC2);
              .list{
                .flex();
                .flex-wrap;
                .flex-row;
                .flex-ai(normal);
                .-t(#137CC2);
                .-b(#137CC2);
                height: 100%;
                //align-items: center;
                .tit{
                  display: flex;
                  flex: 0 171px;
                  //.h(100%);
                  .h(auto);
                  .crop();
                  .mt(0);
                  flex-direction: column;
                  justify-content: center;
                  .bgc(#E9EFF9);
                  .-a(none);
                  .-r(#137CC2);
                  .tc;
                  p{
                    .p(0);
                    .c(#0875C1);
                    .fs(16);
                    .lh(20.03);
                    .bold;
                    //align-self: center;
                  }
                }

                .row-wrap{
                  //flex: 1 0 auto;
                  flex: 0 calc(100% - 171px);
                  //height: 40px;
                  p{
                    flex: 0 171px;
                    .p(0);
                    .h(40);
                    .c(#0875C1);
                    .-r(2px, #137CC2);
                    .-b(#137CC2);
                    .-box;
                    .bgc(#E9EFF9);
                    .tc;
                    .fs(16);
                    .lh(40);
                    .vam();
                    .bold;
                  }
                }
                .row{
                  flex: 1 0  calc(100% - 171px);
                  height: 40px;
                  .flex();

                  input, .select {.h(40); .lh(40); .ib; .vam; .pl(20); .pr(20); .-box;  border:none;}
                  input {
                    .fs(14);
                    /* .w(80%);*/
                    .spoqa;
                    .-box;
                    .-b(#b1b1b1);
                    .c(#555555);
                    flex: 1;
                    &::placeholder {
                      .fs(14);
                      .c(#bbb);
                    }
                    &.email{
                      .c(#0875c1);
                    }
                  }
                  &:last-of-type{
                    input {
                      .-b(none);
                    }
                    .label{
                      .-b(none);
                    }
                  }
                }
              }
            }
          }
          .contact-form02 {
            .w(97.2%);
            .p(0,80);
            .-box;
          }
          .button-wrap{
            .button{
              .wh(206,34);
              .fs(14);
              .lh(34);
              .tc;
            }
          }
          .b-list{
            .mt(12);
            li{
              .flex;
              .space-between;
              .-box;
              &+ li{
                .mt(20);
              }
              .w(100%);
              .h(40);
              .-b();
              .-a(#333);
              .p(11,20);
              .name{
                .fs(14);
                .lh(17.53);
                .c(#000);
                .spoqa;
                &:before{
                  content:'';
                  display:inline-block;
                  .wh(18,18);
                  background:url("/images/pc/file.svg") no-repeat 0 0/100%;
                  background-size:cover;
                  .mr(10);
                  vertical-align: middle;
                  transform: translateY(-1px);
                }
              }
            }
            button{
              width: 18px;
              height: 18px;
              .wh(18,18);
              .bgc;
              .icon-x{
                display: block;
                width: 100%;
                height: 100%;
                background:url("/images/pc/x.svg") no-repeat 0 0/100%;
                background-size:cover;
              }
            }
          }
        }
      }
    }
  }
}


</style>